@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";


html {
	background: $black;
	font-family: $font-default;
	font-size: 16px;
	line-height: 1;

	*::selection{
		background-color: $orange;
		color: $black;
	}

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	background-color: $black;
	color: $white;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
		opacity: .7;
	}
}


.btn{
	display: inline-block;
	text-transform: uppercase;
	font-size: 16px;
	padding: 10px 40px 12px;
	color: $black;
	@include tablet-up{
		font-size: 22px;
	}
	&.btn-blue{
		background-color: $green;
	}
	&.btn-yellow{
		background-color: $orange;
	}
	&.btn-big{
		@include tablet-up{
			padding: 20px 80px;
		}
	}
}

main {
	position: relative;
	#hero{
		position: relative;
		padding-top: 0;
		img{
			display: block;
			margin: 0 auto;
			&+img{
				margin-top: rems(-50);
			}
			&.logo{
				max-width: 60%; 
			}
		}
		div{
			text-align: center;
			padding: 30px 20px 0;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: block;
		}
		@include tablet-up{
			div{
				padding: 60px 20px 0;
				img{
					&.logo{
						max-width: 800px;
					}
				}
			}
		}
	}

	.wrapper{
		position: relative;
		margin: rems(40) 0;
		p.intro{
			font-size: rems(32);
			font-weight: 600;
			line-height: 1.2;
			width: 100%;
			max-width: rems(800);
			margin: 1em auto 0;
			text-transform: uppercase;
			&+p{
				font-size: rems(24);
				font-weight: bold;
				letter-spacing: .2em;
				margin: 0 auto 1em;
			}
		}
		.o{
			color: $orange;
		}
		.g{
			color: $green;
		}
		z-index: 1;
		.container{
			text-align: center;
			img{
				display: inline-block;
			}
			.logo{
				width: 720px;
				max-width: 100%;
			}
			h3{
				@include flex(row,center,center);
				gap: 10px;
				font-size: 10px;
				letter-spacing: 4px;
				margin-bottom: 20px;
				img{
					max-width: 50px;
				}
			}

			h2{
				font-size: 22px;
				letter-spacing: 4px;
			}

			h4{
				font-size: 12px;
				letter-spacing: 4px;
			}
			#events{
				display: grid;
				gap: 40px;
				margin-bottom: 60px;
				.event{
					@include flex(column,center,center);
					text-transform: uppercase;
					font-size: 24px;
					letter-spacing: 2px;
					gap: 6px;
					&.id-103698284, &.id-1026437954{
						display: none;
					}
					.event-date{
						font-weight: 400;
						color: $green;
					}
					.event-location{
						color: $orange;
					}
					&:nth-child(n + 2){
						display: none;
					}
				}
			}
			>.btn{
				margin-bottom: 30px;
			}
			.btn-blue{
				// margin: 120px 0 60px;
			}
		}
	}
	@include tablet-up{
		.wrapper{
			.container{
				max-width: 1400px;
				h3{
					font-size: 21px;
					margin-bottom: 40px;
					img{
						max-width: 90px;
					}
				}
				h2{
					font-size: 45px;
				}
				h4{
					font-size: 22px;
				}
				#events{
					gap: 80px;
					margin-bottom: 120px;
					.event{
						@include flex(row,space-between,flex-start);
						font-size: 44px;
						gap: 30px;
						letter-spacing: unset;
						>div{
							flex: 1;
							text-align: left;
						}
						.event-date{
							max-width: 140px;
						}
						.event-venue{
							flex: 2;
						}
						.event-location{
							max-width: 300px
						}

						.event-links{
							@include flex(row,flex-end,initial);
						}
					}
				}
				>.btn{
					margin-bottom: 80px;
				}
			}
		}
	}

	.btn-orange{
		display: inline-block;
		padding: rems(18) rems(40) rems(15);
		font-size: rems(22);
		font-weight: bold;
		letter-spacing: .2em;
		background-color: $orange;
		color: $white;
		@include hover-focus{
			background-color: $green;
		}
		@media screen and (max-width: 500px){
			font-size: rems(18);
			padding: rems(18) rems(15) rems(15);
		}
	}

	.nl-wrap {
		padding: rems(100) 0;
		text-align: left;
		width: 100%;
		max-width: rems(1000);
		margin: 0 auto;
		display: flex;
		justify-content: center;

		@media screen and (max-width: 800px) {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	
		h2 {
			color: $white;
			text-align: left;
			width: 100%;
			max-width: rems(460);
			letter-spacing: .2em;
			font-size: rems(45);
	
			@media screen and (max-width: 800px) {
				text-align: center;
			}
	
			span {
				display: inline-block;
				color: $orange;
			}
		}
		.newsletter {
			margin-left: rems(30);
	
			@media screen and (max-width: 800px) {
				margin-left: 0;
			}
	
			p {
				font-weight: bold;
			}
	
			input {
				display: inline-block;
				background: none;
				font-size: rems(14);
				font-weight: normal;
				letter-spacing: .1em;
				padding: rems(10) 0;
				border: none;
				border-bottom: 1px solid $white;
				margin-bottom: rems(12);
				margin-right: rems(5);
	
				&::placeholder {
					color: $white;
					text-transform: uppercase;
				}
			}
	
			.btn {
				border: none;
				padding: rems(10) rems(50);
				font-size: rems(14);
				letter-spacing: .1em;
				font-weight: bold;
				transition: .3s all;
				background-color: $green;
				color: $white;
	
				@include hover-focus {
					background-color: $orange;
				}
			}
		}
	
		.text {
			font-size: rems(22);
			margin: rems(60) auto 0;
			padding: 0 rems(10);
			text-align: center;
			width: rems(800);
			max-width: 100%;
	
			a {
				display: inline-block;
				font-weight: bold;
				text-decoration: none;
			}
		}
		.note {
			font-size: .625rem;
			margin-top: .5em;
			width: 100%;
			max-width: 30rem;
			font-family: sans-serif;
			text-transform: uppercase;
		}
	}
}

footer {
	.container{
		.copyright{
			font-size: 12px;
			text-transform: uppercase;
			line-height: 1.2;
			max-width: 720px;
			text-align: center;
			margin: 0 auto;
			font-weight: 300;
			a{
				@include hover-focus{
					color: $orange;
				}
			}
		}
	}

	@include tablet-up{
		.container{
			.copyright{
				font-size: 20px;
			}
		}
	}
}

#events-seated{
	margin-bottom: 80px;
	.seated-events-table{
		border: none;
		@include flex(column,center,center);
		.seated-follow-box{
			display: none;
		}
		.seated-follow-box + div{
			display: none;
			background-color: red;
		}
		.seated-event-row {
			border-bottom: 1px solid $white;
			padding: rems(30) 0;
			display: block;
			width: 100%;
			@include flex(column,center,center);
			gap: 6px;
			div{
				display: block;
				text-align: center;
				width: 100%;
				padding: 0;
				margin: 0;
			}
			.seated-event-description-cells{
				display: block;
				text-transform: uppercase;
				font-size: 18px;
				letter-spacing: 2px;
				.seated-event-date-cell{
					display: block;
					color: $orange;
				}
				.seated-event-details-cell{
					display: none;
				}
				.seated-event-venue-cell{
					@include flex(column-reverse,center,center);
					.seated-event-venue-name{
						display: block;
					}
					.seated-event-venue-location{
						display: block;
					}
				}
			}
			.seated-event-link-cells{
				>div{
					@include flex(column,center,center);
				}
				.seated-event-link {
					background-color: $green;
					color: $white;
					margin: 0;
					font-size: 16px;
					max-width: max-content;
					padding: 10px 40px 12px;
					border-radius: 0;
					border: none;
					letter-spacing: 2px;
				}
			}
		}
	}
	@include tablet-up{
		.seated-events-table{
			.seated-event-row{
				width: 100%;
				@include flex(row,space-between,center);
				>div{
					max-width: unset;
					width: unset;
					flex: 1;
				}
				.seated-event-description-cells{
					@include flex(row,space-between,center);
					gap: 30px;
					font-size: 21px;
					.seated-event-date-cell{
						max-width: unset;
						width: unset;
						text-align: left;
						flex: 1;
						max-width: 230px;
					}
					.seated-event-venue-cell{
						@include flex(row-reverse,flex-end,center);
						max-width: unset;
						width: unset;
						column-gap: 30px;
						flex: 1;
						>div{
							max-width: unset;
							flex: 1;
							text-align: left;
							width: unset;
							&.seated-event-venue-name{
								flex: 2;
							}
						}
					}
				}
				.seated-event-link-cells{
					max-width: max-content;
					.seated-event-link{
						padding: 20px 80px;
						font-size: 16px;
						letter-spacing: .1em;
					}
				}
			}
		}
	}
}